// import { Colors } from "react-native/Libraries/NewAppScreen";
import Base64 from "../services/Base64";
// import { t } from "../services/i18n";

class Query {
  // NOTE: keep members in sync with parse() method
  id: string;
  index: number | undefined;
  name: string;
  emoji: string;
  color: string;
  enabled: boolean;
  lastNotificationDate: Date | undefined;
  queryLocationEnabled: boolean;
  locationDescription: string;
  locationLat: number;
  locationLng: number;
  allWeekdaysEnabled: boolean;
  selectedWeekdays: string;
  timeFrameEnabled: boolean;
  timeFrameMin: number;
  timeFrameMax: number;
  notificationTerm: number;
  notificationTime: string;
  maxTemperatureEnabled: boolean;
  maxTemperatureMin: number;
  maxTemperatureMax: number;
  minTemperatureEnabled: boolean;
  minTemperatureMin: number;
  minTemperatureMax: number;
  cloudinessEnabled: boolean;
  cloudiness: number;
  precipitationEnabled: boolean;
  precipitationMin: number;
  precipitationMax: number;
  windSpeedEnabled: boolean;
  windSpeedMin: number;
  windSpeedMax: number;
  windDirectionEnabled: boolean;
  windDirection: number;
  deactivationEnabled: boolean;
  reactivationOption: number;
  reactivationTempMin: number;
  reactivationTempMax: number;
  reactivationMonth: number;
  constructor(id: string, index: number | undefined, name: string, emoji: string, color: string, enabled: boolean, lastNotificationDate: Date | undefined,
    queryLocationEnabled: boolean, locationDescription: string, locationLat: number, locationLng: number,
    allWeekdaysEnabled: boolean, selectedWeekdays: string,
    timeFrameEnabled: boolean, timeFrameMin: number, timeFrameMax: number,
    notificationTerm: number, notificationTime: string,
    maxTemperatureEnabled: boolean, maxTemperatureMin: number, maxTemperatureMax: number,
    minTemperatureEnabled: boolean, minTemperatureMin: number, minTemperatureMax: number,
    cloudinessEnabled: boolean, cloudiness: number,
    precipitationEnabled: boolean, precipitationMin: number, precipitationMax: number,
    windSpeedEnabled: boolean, windSpeedMin: number, windSpeedMax: number,
    windDirectionEnabled: boolean, windDirection: number,
    deactivationEnabled: boolean, reactivationOption: number, reactivationMonth: number, reactivationTempMin: number, reactivationTempMax: number
  ) {
    this.id = id;
    this.index = index;
    this.name = name;
    this.emoji = emoji;
    this.color = color;
    this.enabled = enabled;
    this.lastNotificationDate = lastNotificationDate;
    this.queryLocationEnabled = queryLocationEnabled;
    this.locationDescription = locationDescription;
    this.locationLat = locationLat;
    this.locationLng = locationLng;
    this.allWeekdaysEnabled = allWeekdaysEnabled;
    this.selectedWeekdays = selectedWeekdays;
    this.timeFrameEnabled = timeFrameEnabled;
    this.timeFrameMin = timeFrameMin;
    this.timeFrameMax = timeFrameMax;
    this.notificationTerm = notificationTerm;
    this.notificationTime = notificationTime;
    this.maxTemperatureEnabled = maxTemperatureEnabled;
    this.maxTemperatureMin = maxTemperatureMin;
    this.maxTemperatureMax = maxTemperatureMax;
    this.minTemperatureEnabled = minTemperatureEnabled;
    this.minTemperatureMin = minTemperatureMin;
    this.minTemperatureMax = minTemperatureMax;
    this.cloudinessEnabled = cloudinessEnabled;
    this.cloudiness = cloudiness;
    this.precipitationEnabled = precipitationEnabled;
    this.precipitationMin = precipitationMin;
    this.precipitationMax = precipitationMax;
    this.windSpeedEnabled = windSpeedEnabled;
    this.windSpeedMin = windSpeedMin;
    this.windSpeedMax = windSpeedMax;
    this.windDirectionEnabled = windDirectionEnabled;
    this.windDirection = windDirection;
    this.deactivationEnabled = deactivationEnabled;
    this.reactivationOption = reactivationOption;
    this.reactivationMonth = reactivationMonth;
    this.reactivationTempMin = reactivationTempMin;
    this.reactivationTempMax = reactivationTempMax;
  }

  static default() {
    return new Query('', undefined, 'newAlarm', '🐝',
      '#ffed9c',//Colors.tileYellow,
      true, undefined,
      false, '', 0, 0,
      true, '',
      false, 12, 15,
      2, '09:00',
      false, -10, 40,
      false, -10, 40,
      false, 1, // cloudiness
      false, 0, 20,
      false, 0, 12,
      false, 0,
      false, 0, 1, 20, 40);
  }

  toBase64() {
    const version = 'v2'; // increase when Query members are changed
    let str = `${version};${this.name};${encodeURIComponent(this.emoji)};` +
      `${this.queryLocationEnabled ? '1' : '0'};` +
      `${this.locationDescription};` +
      `${this.locationLat};${this.locationLng};` +
      `${this.allWeekdaysEnabled ? '1' : '0'};` +
      `${this.selectedWeekdays};` +
      `${this.timeFrameEnabled ? '1' : '0'};` +
      `${this.timeFrameMin};${this.timeFrameMax};` +
      `${this.notificationTerm};` +
      `${this.notificationTime};` +
      `${this.maxTemperatureEnabled ? '1' : '0'};` +
      `${this.maxTemperatureMin};${this.maxTemperatureMax};` +
      `${this.minTemperatureEnabled ? '1' : '0'};` +
      `${this.minTemperatureMin};${this.minTemperatureMax};` +
      `${this.cloudinessEnabled ? '1' : '0'};` +
      `${this.cloudiness};` +
      `${this.precipitationEnabled ? '1' : '0'};` +
      `${this.precipitationMin};${this.precipitationMax};` +
      `${this.windSpeedEnabled ? '1' : '0'};` +
      `${this.windSpeedMin};${this.windSpeedMax};` +
      `${this.windDirectionEnabled ? '1' : '0'};` +
      `${this.windDirection};` +
      `${this.deactivationEnabled ? '1' : '0'};` +
      `${this.reactivationOption};` +
      `${this.reactivationMonth};` +
      `${this.reactivationTempMin};${this.reactivationTempMax}`;
    return Base64.btoa(str);
  }

  static parse(str: string) {
    // console.log(`str: ${str}`);
    str = str.replace(/ /g, '+');
    let decoded = Base64.atob(str);
    // console.log(`decoded: ${decoded}`);
    let elements: string[] = decoded.split(';');
    if (elements[0] === 'v1') {
      let query = Query.parseV1(elements);
      return query;
    } else if (elements[0] === 'v2') {
      let query = Query.parseV2(elements);
      return query;
    } else {
      return null;
    }
  }

  static parseV1(elements: string[]) {
    let query = Query.default();
    if (elements.length === 30) {
      // skip id, color, enabled
      query.name = elements[1];
      query.emoji = decodeURIComponent(elements[2]);
      query.queryLocationEnabled = elements[3] === '1';
      query.locationDescription = elements[4];
      query.locationLat = parseFloat(elements[5]);
      query.locationLng = parseFloat(elements[6]);
      query.allWeekdaysEnabled = elements[7] === '1';
      query.selectedWeekdays = elements[8];
      query.timeFrameEnabled = elements[9] === '1';
      query.timeFrameMin = parseInt(elements[10]);
      query.timeFrameMax = parseInt(elements[11]);
      query.notificationTerm = parseInt(elements[12]);
      query.notificationTime = elements[13];
      query.maxTemperatureEnabled = elements[14] === '1';
      query.maxTemperatureMin = parseInt(elements[15]);
      query.maxTemperatureMax = parseInt(elements[16]);
      query.minTemperatureEnabled = elements[17] === '1';
      query.minTemperatureMin = parseInt(elements[18]);
      query.minTemperatureMax = parseInt(elements[19]);
      query.cloudinessEnabled = elements[20] === '1';
      query.cloudiness = parseInt(elements[21]);
      query.precipitationEnabled = elements[22] === '1';
      query.precipitationMin = parseInt(elements[23]);
      query.precipitationMax = parseInt(elements[24]);
      query.windSpeedEnabled = elements[25] === '1';
      query.windSpeedMin = parseInt(elements[26]);
      query.windSpeedMax = parseInt(elements[27]);
      query.windDirectionEnabled = elements[28] === '1';
      query.windDirection = parseInt(elements[29]);
      return query;
    } else {
      console.log(`Unexpected number of elements for v1: ${elements.length}`);
      return null;
    }
  }

  static parseV2(elements: string[]) {
    let query = Query.default();
    if (elements.length === 35) {
      // skip id, color, enabled
      query.name = elements[1];
      query.emoji = decodeURIComponent(elements[2]);
      query.queryLocationEnabled = elements[3] === '1';
      query.locationDescription = elements[4];
      query.locationLat = parseFloat(elements[5]);
      query.locationLng = parseFloat(elements[6]);
      query.allWeekdaysEnabled = elements[7] === '1';
      query.selectedWeekdays = elements[8];
      query.timeFrameEnabled = elements[9] === '1';
      query.timeFrameMin = parseInt(elements[10]);
      query.timeFrameMax = parseInt(elements[11]);
      query.notificationTerm = parseInt(elements[12]);
      query.notificationTime = elements[13];
      query.maxTemperatureEnabled = elements[14] === '1';
      query.maxTemperatureMin = parseInt(elements[15]);
      query.maxTemperatureMax = parseInt(elements[16]);
      query.minTemperatureEnabled = elements[17] === '1';
      query.minTemperatureMin = parseInt(elements[18]);
      query.minTemperatureMax = parseInt(elements[19]);
      query.cloudinessEnabled = elements[20] === '1';
      query.cloudiness = parseInt(elements[21]);
      query.precipitationEnabled = elements[22] === '1';
      query.precipitationMin = parseInt(elements[23]);
      query.precipitationMax = parseInt(elements[24]);
      query.windSpeedEnabled = elements[25] === '1';
      query.windSpeedMin = parseInt(elements[26]);
      query.windSpeedMax = parseInt(elements[27]);
      query.windDirectionEnabled = elements[28] === '1';
      query.windDirection = parseInt(elements[29]);
      query.deactivationEnabled = elements[30] == '1';
      query.reactivationOption = parseInt(elements[31]);
      query.reactivationMonth = parseInt(elements[32]);
      query.reactivationTempMin = parseInt(elements[33]);
      query.reactivationTempMax = parseInt(elements[34]);
      return query;
    } else {
      console.log(`Unexpected number of elements for v1: ${elements.length}`);
      return null;
    }
  }
}

export default Query;
