import { Col, Container, Row, Stack } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import downloadApple from '../../media/app-store-badge-nl.png';
import downloadGoogle from '../../media/google-play-badge-nl.png';

const HetBouwenVanEenOverkappingPage = () => {

  const navigate = useNavigate();

  return (
    <div className="App">

      <Helmet prioritizeSeoTags>
        <title>Het bouwen van een overkapping | BuzzerBee weermeldingen</title>
        <meta name="description" content="Wat is het beste weer voor het bouwen van een overkapping? Ontvang op tijd een melding voor jouw locatie." />
        <link rel="canonical" href="https://buzzerbee.buzzerbee.nl/alerts/het-bouwen-van-een-overkapping" />
      </Helmet>

      <div className="Header">
        <Container>
          <Row>
            <Col>
              <h1 className="mt-2 mb-4">Het bouwen van een overkapping</h1>
              <p>
                Wil je gewaarschuwd worden als het goed weer wordt om een overkapping te bouwen?
              </p>
              <p>
                Installeer dan de <Link to={'/'}>BuzzerBee app</Link> op jouw iPhone of Android-smartphone, klik daarna op onderstaande knop en pas de melding helemaal naar je eigen wensen aan. Je ontvangt dan een gratis notificatie als het goed weer is voor het bouwen van een overkapping op de locatie die jij hebt ingesteld.
              </p>

              <Stack direction="horizontal" className="AlarmContainer mb-4" onClick={() => {
                window.location.href = `/details/djI7SGV0IGJvdXdlbiB2YW4gZWVuIG92ZXJrYXBwaW5nOyVFMiU5QiVBOSVFRiVCOCU4RjswOzswOzA7MTs7MDsxMjsxNTsyOzA5OjAwOzA7LTEwOzQwOzA7LTEwOzQwOzA7MTswOzA7MjA7MDswOzEyOzA7MDswOzA7MTsyMDs0MA==`;
                return null;
              }}>
                <div className="AlarmColumn" style={{fontSize: 35, marginLeft: -15, marginRight: -10}}>⛩️</div>
                <div className="AlarmColumn" style={{textAlign: 'left'}}>Het bouwen van een overkapping</div>
              </Stack>

              <h2>Wat is het ideale weer om een overkapping te bouwen?</h2>
              <p>
                Het beste weer voor het bouwen van een overkapping is <b>droog weer, weinig wind en niet te koud</b>. Wil je weten wanneer er op jouw locatie goede weersomstandigheden zijn om een overkapping te bouwen? Installeer dan de gratis BuzzerBee app op jouw mobiel:
              </p>
              <Container className='download-button-container justify-content-center'>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="download-button-item download-button-ios">
                      <a href="https://apps.apple.com/nl/app/buzzerbee/id1539478065" target={'_blank'}>
                        <img src={downloadApple} className="download-button" alt="logo" />  
                      </a>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='download-button-item download-button-android'>
                      <a href="https://play.google.com/store/apps/details?id=nl.buzzerbee.app&gl=NL" target={'_blank'}>
                        <img src={downloadGoogle} className="download-button" alt="logo" />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>

    </div>
  );

}
export default HetBouwenVanEenOverkappingPage;
