import React, { useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import infinityVideo from '../media/infinity.mp4';
// import sortAlarmsVideo from '../media/sort-alarms.mp4';
import sortAlarmsImage from '../media/sort-alarms.png';
import infinityImage from '../media/infinity.png';
import mutedAlarms from '../media/muted-alarms.png';
import badgeCountImage from '../media/badge-count.png';
import shareAlarmImage from '../media/share-alarm.png';
import protectPlantsImage from '../media/protect-plants.png';
import lastWeeksNotificationsImage from '../media/last-weeks-notifications.png';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const WhatsNewPage = () => {

  return (
    <div className="App">

      <Helmet prioritizeSeoTags>
        <title>Nieuw | BuzzerBee weermeldingen</title>
        <link rel="canonical" href="https://buzzerbee.buzzerbee.nl/whatsnew" />
      </Helmet>
      
      <div className="Header">
        <Container>
          <Row>
            <Col lg={6}>
              <h2 className="mt-2 mb-4">
                Recente notificaties
              </h2>
              <p className="font-italic" style={{fontStyle: 'italic', marginTop: -20}}>
                {/* 28-03-2022 */}
                Versie 0.3.4
              </p>
              <p>
                Naast de actuele notificaties worden nu ook de notificaties van de <b>afgelopen week</b> getoond. Handig wanneer je er een gemist zou hebben. De meest recente staat altijd bovenaan. Bovendien kun je een notificatie vanuit de app direct doorsturen naar je vrienden. 
              </p>
            </Col>
            <Col lg={6} style={{ display: 'flex', alignItems:'center', justifyContent: 'center'}}>
              <img
                className="img-screenshot"
                alt="last-weeks-notifications-image"
                src={lastWeeksNotificationsImage}
                style={{ maxWidth: '90%', maxHeight: '40vh' }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="Header">
        <Container>
          <Row>
            <Col lg={6}>
              <h2 className="mt-2 mb-4">
                Planten beschermen tegen vorst
              </h2>
              <p className="font-italic" style={{fontStyle: 'italic', marginTop: -20}}>
                {/* 28-03-2022 */}
                Versie 0.3.3
              </p>
              <p>
                We hebben een handige extra voorbeeld-melding toegevoegd voor het beschermen van planten tegen vorst. Wil je weten welke planten je moet beschermen tegen vorst en hoe je dit kunt doen? Kijk <Link to={'/alerts/planten-beschermen-tegen-vorst'}>hier</Link> eens. Natuurlijk is het altijd mogelijk om het voorbeeld aan je eigen wensen aan te passen, zodat je op het juiste moment een notificatie ontvangt om je planten te beschermen.
              </p>
            </Col>
            <Col lg={6} style={{ display: 'flex', alignItems:'center', justifyContent: 'center'}}>
              <img
                className="img-screenshot"
                alt="protect-plants-image"
                src={protectPlantsImage}
                style={{ maxWidth: '90%', maxHeight: '40vh' }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="Header">
        <Container>
          <Row>
            <Col lg={6}>
              <h2 className="mt-2 mb-4">
                Gedempte alarmen
              </h2>
              <p className="font-italic" style={{fontStyle: 'italic', marginTop: -20}}>
                {/* 28-03-2022 */}
                Versie 0.3.2
              </p>
              <p>
                In de laatste versie is de weergave van <b>gedempte alarmen</b> verbeterd. Dit wordt duidelijk aangegeven met een pictogram. Wanneer je er op klikt, kun je precies zien wanneer het weer geactiveerd zal worden.
              </p>
              <p>
                Wanneer het openen van de app onverhoopt iets langer duurt, hebben we iets leuks voor je in petto, zodat je hierna -een beetje wijzer- alsnog snel van de app gebruik kan maken. Ook bevat deze update wat een verbeterde layout wanneer je een grote of kleine letttergrootte op je telefoon hebt ingesteld.
              </p>
            </Col>
            <Col lg={6} style={{ display: 'flex', alignItems:'center', justifyContent: 'center'}}>
              <img
                className="img-screenshot"
                alt="sort-alarms-image"
                src={mutedAlarms}
                style={{ maxWidth: '90%', maxHeight: '40vh' }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="Header">
        <Container>
          <Row>
            <Col lg={6}>
              <h2 className="mt-2 mb-4">
                Oneindige mogelijkheden
              </h2>
              <p className="font-italic" style={{fontStyle: 'italic', marginTop: -20}}>
                {/* 28-03-2022 */}
                Versie 0.3.1
              </p>
              <p>
                In versie 0.3.1 van de app kun je het bereik van bijvoorbeeld temperatuur en neerslag tot <b>oneindig</b> instellen.
                Dit biedt je nóg meer mogelijkheden om je melding op jouw wensen af te stemmen.
                Enkele voorbeelden in de app maken hier nu ook gebruik van.
              </p>
              <p>
                Daarnaast zijn er onder de motorkap verschillende wijzigingen gedaan om de gebruikerservaring
                te verbeteren.
              </p>
            </Col>
            <Col lg={6} style={{ display: 'flex', alignItems:'center', justifyContent: 'center'}}>
              <img
                className="img-screenshot"
                alt="sort-alarms-image"
                src={infinityImage}
                style={{ maxWidth: '90%', maxHeight: '40vh' }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="Header">
        <Container>
          <Row>
            <Col lg={6}>
              <h2 className="mt-2 mb-4">
                Meldingen ordenen en delen
              </h2>
              <p className="font-italic" style={{fontStyle: 'italic', marginTop: -20}}>
                {/* 09-12-2021 */}
                Versie 0.3.0
              </p>
              <p>
                Nieuw in versie 0.3.0 is de mogelijkheid je meldingen te <b>ordenen</b>. 
                Pak een melding vast en versleep het naar boven of onder.
              </p>
              <p>
                Swipe je een melding naar links, dan verschijnen twee opties in het snelmenu.
                Hier kun je kiezen voor <b>delen</b> om jouw eigen melding met anderen te delen.
                Ook kun je eenvoudig een melding uit je lijst <b>verwijderen</b>.
              </p>
            </Col>
            <Col lg={6} style={{ display: 'flex', alignItems:'center', justifyContent: 'center'}}>
              <img
                className="img-screenshot"
                alt="infinity-image"
                src={sortAlarmsImage}
                style={{ maxWidth: '90%', maxHeight: '40vh' }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="Header">
        <Container>
          <Row>
            <Col>
            {/* <Col lg={6}> */}
              <h2 className="mt-2 mb-4">
                Badges
              </h2>
              <p className="font-italic" style={{fontStyle: 'italic', marginTop: -20}}>
                {/* 25-02-2020 */}
                Versie 0.2.2
              </p>
              <p>
                Vanaf versie 0.2.2 hoef je geen notificaties meer te missen.
                Het aantal ongeopende notificaties verschijnt in het rode bolletje boven
                het BuzzerBee app-icoon en in de Inbox-tab in de app.
              </p>
            </Col>
            <Col lg={6} style={{ display: 'flex', alignItems:'center', justifyContent: 'center'}}>
              <img
                className="img-screenshot"
                alt="badge-count"
                src={badgeCountImage}
                style={{ maxWidth: '90%', maxHeight: '40vh' }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="Header">
        <Container>
          <Row>
            <Col>
            {/* <Col lg={6}> */}
              <h2 className="mt-2 mb-4">
                Meldingen delen
              </h2>
              <p className="font-italic" style={{fontStyle: 'italic', marginTop: -20}}>
                {/* 15-02-2020 */}
                Versie 0.2.1
              </p>
              <p>
                Met versie 0.2.1 van de app kun je jouw meldingen nu ook delen met vrienden.
                Druk in Mijn Meldingen op het icoontje om een melding te delen.
                Je kunt nu een linkje delen die de ontvanger direct kan openen met de BuzzerBee app.
                De melding wordt zo direct aan zijn of haar lijst toegevoegd.
                Handig voor een gezamenlijke activiteit!
              </p>
            </Col>
            <Col lg={6} style={{ display: 'flex', alignItems:'center', justifyContent: 'center'}}>
              <img
                className="img-screenshot"
                alt="share-alarm"
                src={shareAlarmImage}
                style={{ maxWidth: '90%', maxHeight: '40vh' }}
              />
            </Col>
          </Row>
        </Container>
      </div>

    </div>
  );

}
export default WhatsNewPage;
