import React, { useRef, useState } from 'react';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { mdiBellPlusOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Helmet } from 'react-helmet-async';

const OutsideTapPage = () => {

  const navigate = useNavigate();

  return (
    <div className="App">

      <Helmet prioritizeSeoTags>
        <title>Buitenkraan afsluiten bij vorst | BuzzerBee weermeldingen</title>
        <meta name="description" content="Ontdek waarom je de buitenkraan moet dichtdraaien bij vorst en hoe je dit doet. Ontvang op tijd een melding wanneer het gaat vriezen op jouw locatie." />
        <link rel="canonical" href="https://buzzerbee.buzzerbee.nl/alerts/buitenkraan-afsluiten-bij-vorst" />
      </Helmet>

      <div className="Header">
        <Container>
          <Row>
            <Col>
              <h1 className="mt-2 mb-4">Buitenkraan afsluiten bij vorst</h1>

              {/* <p>
                Wil je een notificatie op je telefoon ontvangen wanneer je de buitenkraan moet afsluiten?
                Installeer de <Link to={'/'}>Buzzerbee app</Link> en klik op onderstaande melding om deze toe te voegen:
              </p> */}
              <p>
                Wil je van tevoren een gratis notificatie op je smartphone ontvangen wanneer het gaat vriezen op een locatie die jij hebt ingesteld?
                Installeer de <Link to={'/'}>BuzzerBee app</Link> op jouw iPhone of Android-smartphone en klik op onderstaande melding om deze toe te voegen aan jouw alarmen:
              </p>

              <Stack direction="horizontal" className="AlarmContainer mb-4" onClick={() => {
                window.location.href = `/details/djI7QnVpdGVua3JhYW4gYWZzbHVpdGVuOyVGMCU5RiU5QSVCMDswOzswOzA7MTs7MDsxMjsxNTsxOzE4OjAwOzA7LTEwOzQwOzE7LTEwOzA7MDsxOzA7MDsyMDswOzA7MTI7MDswOzA7MDsxOzIwOzQw`;
                return null;
              }}>
                <div className="AlarmColumn" style={{fontSize: 35, marginLeft: -15, marginRight: -10}}>🚰</div>
                <div className="AlarmColumn" style={{textAlign: 'left'}}>Buitenkraan afsluiten</div>
                {/* <div className="AlarmColumn">
                  <Icon path={mdiBellPlusOutline}
                    size={1.5}
                    color="black"/>
                </div> */}
                {/* <Col className="AlarmColumn" style={{ fontSize: 40 }}>🚰</Col>
                <Col className="AlarmColumn">Buitenkraan afsluiten</Col>
                <Col className="AlarmColumn"> */}
                  {/* <span className="mdi mdi-name">F14BB</span> */}
                  {/* https://pictogrammers.github.io/@mdi/font/5.3.45/ */}
                  {/* &#xE87C; face */}
                {/* </Col> */}
              </Stack>

              <h2>Wanneer moet ik de buitenkraan dichtdraaien?</h2>
              <p>
                Het is verstandig om de buitenkraan dicht te draaien wanneer de temperatuur 's nachts tot <b>onder 0 °C</b> daalt.
              </p>
              <h2>Waarom moet je een buitenkraan tegen vorst beschermen?</h2>
              <p>
                Het is belangrijk om de buitenkraan in de winter goed te beschermen tegen vorst. Als water in de kraan of leidingen bevriest kan dit namelijk leiden tot lekkage.
              </p>
              <h2>Hoe bescherm je de buitenkraan tegen vorst?</h2>
              <p>
                Dit kun je doen door de kraan af te sluiten en eventueel de kraan en leidingen te isoleren.
              </p>
              <h2>Hoe sluit ik de buitenkraan af?</h2>
              <p>
                Een buitenkraan kun je afsluiten door gebruik te maken van een afsluitkraan of een afsluitventiel. Dit is een kleine kraan die je kunt openen en sluiten om de watertoevoer naar de buitenkraan te regelen. Deze vind je vaak in de kelder, garage, meterkast of in de kruipruimte onder het huis. Nadat je deze kraan hebt gesloten is het belangrijk om de buitenkraan helemaal leeg te laten lopen, zodat er geen water achterblijft in de leidingen. Als water in de leidingen bevriest kan dit namelijk leiden tot lekkage of breuk van de leidingen. Zet de buitenkraan open totdat er geen druppel meer uit komt en draai deze dan weer dicht.
              </p>
              <h2>Hoe isoleer ik de leidingen en buitenkraan?</h2>
              <p>
                Het isoleren van de leidingen en/of buitenkraan kan bijvoorbeeld door het aanbrengen van een <a href="https://www.tuinadvies.nl/tuinwinkel/product/4640/isolatie-buitenkraan" target="_blank">isolatiehuls</a>, isolatietape, een isolatiedeken of een <a href="https://www.tuinadvies.nl/tuinwinkel/product/3685/waterkraan-anti-vorstkap" target="_blank">afdekkap</a>. Ook als je deze isolatiemaatregelen toepast, blijft het belangrijk om de buitenkraan af te sluiten.
              </p>
              <h2>Wat te doen bij een bevroren buitenkraan?</h2>
              <p>
                Als de kraan of leidingen bevroren zijn geraakt en nog water bevatten, kun je het water ontdooien met behulp van een haardroger of een andere warmtebron zoals een kacheltje. Zodra het water is ontdooid kun je de buitenkraan afsluiten.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

    </div>
  );

}
export default OutsideTapPage;
