import { Col, Container, Row, Stack } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import downloadApple from '../../media/app-store-badge-nl.png';
import downloadGoogle from '../../media/google-play-badge-nl.png';

const SnowboardenPage = () => {

  const navigate = useNavigate();

  return (
    <div className="App">

      <Helmet prioritizeSeoTags>
        <title>Snowboarden | BuzzerBee weermeldingen</title>
        <meta name="description" content="Wat is het beste weer voor snowboarden? Ontvang op tijd een melding voor jouw locatie." />
        <link rel="canonical" href="https://buzzerbee.buzzerbee.nl/alerts/snowboarden" />
      </Helmet>

      <div className="Header">
        <Container>
          <Row>
            <Col>
              <h1 className="mt-2 mb-4">Snowboarden</h1>
              <p>
                Wil je gewaarschuwd worden als het goed weer wordt om te snowboarden?
              </p>
              <p>
                Installeer dan de <Link to={'/'}>BuzzerBee app</Link> op jouw iPhone of Android-smartphone, klik daarna op onderstaande knop en pas de melding helemaal naar je eigen wensen aan. Je ontvangt dan een gratis notificatie als het goed weer is voor snowboarden op de locatie die jij hebt ingesteld.
              </p>

              <Stack direction="horizontal" className="AlarmContainer mb-4" onClick={() => {
                window.location.href = `/details/djI7U25vd2JvYXJkZW47JUYwJTlGJThGJTgyOzA7OzA7MDsxOzswOzEyOzE1OzI7MDk6MDA7MDstMTA7NDA7MDstMTA7NDA7MDsxOzA7MDsyMDswOzA7MTI7MDswOzA7MDsxOzIwOzQw`;
                return null;
              }}>
                <div className="AlarmColumn" style={{fontSize: 35, marginLeft: -15, marginRight: -10}}>🏂</div>
                <div className="AlarmColumn" style={{textAlign: 'left'}}>Snowboarden</div>
              </Stack>

              <h2>Wat is het ideale weer om te snowboarden?</h2>
              <p>
                Het beste weer voor snowboarden is <b>droog, besneeuwd weer</b>. Wil je weten wanneer er op jouw locatie goede weersomstandigheden zijn om te snowboarden? Installeer dan de gratis BuzzerBee app op jouw mobiel:
              </p>
              <Container className='download-button-container justify-content-center'>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="download-button-item download-button-ios">
                      <a href="https://apps.apple.com/nl/app/buzzerbee/id1539478065" target={'_blank'}>
                        <img src={downloadApple} className="download-button" alt="logo" />  
                      </a>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='download-button-item download-button-android'>
                      <a href="https://play.google.com/store/apps/details?id=nl.buzzerbee.app&gl=NL" target={'_blank'}>
                        <img src={downloadGoogle} className="download-button" alt="logo" />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>

    </div>
  );

}
export default SnowboardenPage;
