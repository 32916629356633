import React, { useRef, useState } from 'react';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { mdiBellPlusOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Helmet } from 'react-helmet-async';
import imageWeatherAlert from '../media/weather-alert.png';

const WeatherAlertPage = () => {

  const navigate = useNavigate();

  return (
    <div className="App">

      <Helmet prioritizeSeoTags>
        <title>Weerwaarschuwingen KNMI | BuzzerBee weermeldingen</title>
        <meta name="description" content="Welke weerwaarschuwingen bestaan er in Nederland? Ontvang gratis een melding bij code rood, oranje of geel." />
        <link rel="canonical" href="https://buzzerbee.buzzerbee.nl/alerts/weerwaarschuwingen-knmi" />
      </Helmet>

      <div className="Header">
        <Container>
          <Row>
            <Col>
              <h1 className="mt-2 mb-4">Weerwaarschuwingen KNMI</h1>

              <p>
                Wil je gewaarschuwd worden als het KNMI een waarschuwing afgeeft? Installeer de <Link to={'/'}>BuzzerBee app</Link> op jouw iPhone of Android-smartphone.
                Je ontvangt dan gratis een notificatie als er een KNMI-waarschuwing is voor de locatie die jij hebt ingesteld. In de melding die je ontvangt staat welke code geldt en van hoelaat tot hoelaat.
              </p>

            <div style={{display: 'flex', justifyContent: 'center'}}>
              <img 
                src={imageWeatherAlert}
                alt="weather-alert"
                className="img-screenshot" 
                style={{ maxWidth: '100%', maxHeight: '10vh'}}
              />
            </div>

              <h2>Wat zijn KNMI weerwaarschuwingen?</h2>
              <p>
                KNMI weerwaarschuwingen worden uitgegeven door het KNMI (Koninklijk Nederlands Meteorologisch Instituut) wanneer er weersomstandigheden worden verwacht die extra alertheid vragen en mogelijk impact hebben, zoals zware regen, storm of een hittegolf. De waarschuwingen worden uitgegeven per provincie en bestaan uit een kleurcode en adviezen, zoals bijvoorbeeld het advies om niet de weg op te gaan. De waarschuwingen hebben als doel de veiligheid zoveel mogelijk te waarborgen.
              </p>
              <h2>Welke weercodes zijn er?</h2>
              <p>
                Het KNMI gebruikt verschillende weercodes. Het verschil zit in de ernst van de verwachtte weersituatie en de mogelijke impact op de samenleving.
                <ul>
                  <li><b>Code <span style={{/*color: 'red'*/}}>rood</span></b>: weeralarm vanwege gevaarlijke weersomstandigheden die mogelijk de maatschappij ontwrichten, zoals zeer zware windstoten of sneeuwjacht</li>
                  <li><b>Code <span style={{/*color: 'orange'*/}}>oranje</span></b>: grote kans op gevaarlijke weersomstandigheden, bijvoorbeeld vanwege zware regenval, zware windstoten, extreme hitte of dichte mist</li>
                  <li><b>Code <span style={{/*color: 'yellow', textShadow: '1px 1px 0px black, -1px -1px 0px black, -1px 1px 0px black, 1px -1px 0px black'*/}}>geel</span></b>: mogelijk kans op gevaarlijke weersomstandigheden, bijvoorbeeld vanwege sneeuwval, veel regen of lokale gladheid door ijzel</li>
                  <li><b>Code <span style={{/*color: 'green'*/}}>groen</span></b>: normale weersomstandigheden waarbij geen waarschuwing nodig is</li>
                </ul>
              </p>
              <p>
                Op de <a href="https://www.knmi.nl/kennis-en-datacentrum/uitleg/knmi-waarschuwingen" target="_blank">website van het KNMI</a> is meer te lezen over de KNMI waarschuwingen en criteria die gebruikt worden om de weercode te bepalen.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

    </div>
  );

}
export default WeatherAlertPage;
