import React, { useRef, useState } from 'react';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { mdiBellPlusOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Helmet } from 'react-helmet-async';

const ProtectPlantsPage = () => {

  const navigate = useNavigate();

  return (
    <div className="App">

      <Helmet prioritizeSeoTags>
        <title>Planten beschermen tegen vorst | BuzzerBee weermeldingen</title>
        <meta name="description" content="Ontdek welke planten je het best kunt beschermen tegen vorst en hoe je dit doet. Ontvang op tijd een melding wanneer het gaat vriezen op jouw locatie." />
        <link rel="canonical" href="https://buzzerbee.buzzerbee.nl/alerts/planten-beschermen-tegen-vorst" />
      </Helmet>

      <div className="Header">
        <Container>
          <Row>
            <Col>
              <h1 className="mt-2 mb-4">Planten beschermen tegen vorst</h1>
              <p>
                Wil je gewaarschuwd worden als het tijd is om de kwetsbare planten te beschermen? Installeer de <Link to={'/'}>BuzzerBee app</Link> op jouw iPhone of Android-smartphone en klik op onderstaande melding. Je ontvangt dan een gratis notificatie als het gaat vriezen op een locatie die jij hebt ingesteld.
              </p>

              <Stack direction="horizontal" className="AlarmContainer mb-4" onClick={() => {
                window.location.href = `/details/djI7UGxhbnRlbiBiZXNjaGVybWVuIHRlZ2VuIHZvcnN0OyVGMCU5RiU4QyVCMTswOzswOzA7MTs7MDsxMjsxNTsxOzE4OjAwOzA7LTEwOzQwOzE7LTEwMDAwMDA7MDswOzE7MDswOzIwOzA7MDsxMjswOzA7MTszOzE7ODsxMDAwMDAw`;
                return null;
              }}>
                <div className="AlarmColumn" style={{fontSize: 35, marginLeft: -15, marginRight: -10}}>🌱</div>
                <div className="AlarmColumn" style={{textAlign: 'left'}}>Planten beschermen tegen vorst</div>
              </Stack>

              <h2>Waarom moet je planten tegen vorst beschermen?</h2>
              <p>
                Sommige planten kunnen niet goed tegen vorst en hebben moeite om te overleven in de kou. Het is daarom belangrijk om deze goed te beschermen tegen vorst.
              </p>
              <h2>Welke planten moet je tegen vorst beschermen?</h2>
              <p>
                Planten die niet winterhard zijn hebben bij koud weer extra bescherming nodig. Dit zijn onder andere de olijfboom, Oleander, Camelia en Agapanthus.
              </p>
              <h2>Hoe bescherm je de planten tegen vorst?</h2>
              <p>
                Planten die niet in de volle grond staan kun je beschermen door het plaatsen van noppenfolie in of om de pot of de planten te verplaatsen naar een vorstvrije plek zoals een schuur of garage. Planten die niet te verplaatsen zijn kun je beschermen met een <a href="https://www.tuinadvies.nl/tuinwinkel/categorie/wintertips/vorstbescherming/neutrale-plantenhoezen" target="_blank">plantenhoes</a> en eventueel ook van een <a href="https://www.tuinadvies.nl/tuinwinkel/product/2102/verwarmingssnoer-4-m" target="_blank">warmtekabel</a> voorzien. Ook het aanbrengen van een laag mulch rondom de plant zorgt voor extra bescherming.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

    </div>
  );

}
export default ProtectPlantsPage;
