import React, { useRef, useEffect, useState } from 'react';
import downloadApple from '../media/app-store-badge-nl.png';
import downloadGoogle from '../media/google-play-badge-nl.png';
import '../App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet-async';
import { Stack } from 'react-bootstrap';

const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const HomePage = () => {

  const video1Ref = useRef<HTMLVideoElement>(null);
  const video2Ref = useRef<HTMLVideoElement>(null);
  const [isPlaying1, setPlaying1] = useState<boolean>(false);
  const [isPlaying2, setPlaying2] = useState<boolean>(false);

  // useEffect(() => {
  //   async function playVideo() {
  //     await sleep(2000);
  //     if (videoRef.current) {
  //       videoRef.current.play();
  //     } else {
  //       console.log('videoRef undefined');
  //     }
  //   }
  //   playVideo();
    
  // }, []);

  return (
    <div className="App">

      <Helmet prioritizeSeoTags>
        <title>Homepage | BuzzerBee weermeldingen</title>
        <link rel="canonical" href="https://buzzerbee.buzzerbee.nl" />
      </Helmet>

      <div className="Header">
        <h1 className="mt-2 mb-4">
          BuzzerBee weermeldingen app
        </h1>
        <p className="header-text">
          Met de BuzzerBee app 🐝 maak je <b>gratis</b> en <b>eenvoudig</b> je eigen weermeldingen.
        </p>
        <p>
          Je krijgt een notificatie wanneer de weersvoorspellingen ideaal zijn voor jouw activiteiten. Handig bij barbecueweer ♨️, surfweer 🏄‍♀️, fietsweer 🚴‍♂️ of weer om te parachutespringen 🪂.
        </p>
        <p>
          Daarnaast kan de app je ook de weerwaarschuwingen van het KNMI sturen, zodat je tijdig voorzorgsmaatregelen kunt nemen bij bijvoorbeeld gladheid of storm.
        </p>
        <p>
          Installeer de gratis BuzzerBee app op jouw iPhone of Android-toestel:
        </p>
        <Container className='download-button-container justify-content-center'>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="download-button-item download-button-ios">
                <a href="https://apps.apple.com/nl/app/buzzerbee/id1539478065" target={'_blank'}>
                  <img src={downloadApple} className="download-button" alt="logo" />  
                </a>
              </div>
            </Col>
            <Col md={6}>
              <div className='download-button-item download-button-android'>
                <a href="https://play.google.com/store/apps/details?id=nl.buzzerbee.app&gl=NL" target={'_blank'}>
                  <img src={downloadGoogle} className="download-button" alt="logo" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="TileContainer">
        <Container fluid>
          <Row>
            <Col md={6} className="mx-0 px-0 mb-2 mb-md-0 d-flex justify-content-start">
              <div className="Tile p-3">
                  <h3 className="no-margin-top">Maak je eigen weermelding</h3>
                  <p>
                    Maak je eigen weermelding<br/>
                    Lees meer...
                  </p>
              </div>
            </Col>
            <Col md={6} className="px-0 mx-0 mb-2 mb-md-0 d-flex justify-content-end">
              <div className="Tile TileRight p-3">
                <h3 className="no-margin-top">Ontdek: KNMI weermeldingen</h3>
                <p>
                    KNMI<br/>
                    Lees meer...
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}

    </div>
  );
}
export default HomePage;
