import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from './components/navbar/NavBar';
import HomePage from './pages/HomePage';
import WhatsNewPage from './pages/WhatsNewPage';
import OutsideTapPage from './pages/OutsideTapPage';
import ProtectPlantsPage from './pages/ProtectPlantsPage';
import DetailsPage from './pages/DetailsPage';
// import WebFont from 'webfontloader';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import WeatherAlertPage from './pages/WeatherAlertPage';
import CreateAlarmPage from './pages/CreateAlarmPage';
import GeneratedPage from './pages/Generated/GeneratedPage';

import EenBallonvaartPage from './pages/Generated/PageEenBallonvaart'
import BarbecueenPage from './pages/Generated/PageBarbecueen'
import BeachvolleybalPage from './pages/Generated/PageBeachvolleybal'
import BergbeklimmenPage from './pages/Generated/PageBergbeklimmen'
import BoogschietenPage from './pages/Generated/PageBoogschieten'
import EenBoottochtPage from './pages/Generated/PageEenBoottocht'
import BoulderenPage from './pages/Generated/PageBoulderen'
import BuitenKlimmenPage from './pages/Generated/PageBuitenKlimmen'
import BuitenYogaPage from './pages/Generated/PageBuitenYoga'
import BuitenZwemmenPage from './pages/Generated/PageBuitenZwemmen'
import BuitenconcertenPage from './pages/Generated/PageBuitenconcerten'
import BuitentheaterPage from './pages/Generated/PageBuitentheater'
import CanyoningPage from './pages/Generated/PageCanyoning'
import DeltavliegenPage from './pages/Generated/PageDeltavliegen'
import DiepzeeduikenPage from './pages/Generated/PageDiepzeeduiken'
import HetBezoekenVanEenDierentuinPage from './pages/Generated/PageHetBezoekenVanEenDierentuin'
import DuikenPage from './pages/Generated/PageDuiken'
import EenFestivalPage from './pages/Generated/PageEenFestival'
import FietsenPage from './pages/Generated/PageFietsen'
import FrisbeeenPage from './pages/Generated/PageFrisbeeen'
import GolfenPage from './pages/Generated/PageGolfen'
import GravelbikenPage from './pages/Generated/PageGravelbiken'
import EenHelikoptervluchtPage from './pages/Generated/PageEenHelikoptervlucht'
import IJsklimmenPage from './pages/Generated/PageIJsklimmen'
import KajakkenPage from './pages/Generated/PageKajakken'
import KamperenPage from './pages/Generated/PageKamperen'
import KanoenPage from './pages/Generated/PageKanoen'
import KitesurfenPage from './pages/Generated/PageKitesurfen'
import MountainbikenPage from './pages/Generated/PageMountainbiken'
import MuseaBezoekenPage from './pages/Generated/PageMuseaBezoeken'
import OffRoadRijdenPage from './pages/Generated/PageOffRoadRijden'
import HetBezoekenVanEenOpenluchttheaterPage from './pages/Generated/PageHetBezoekenVanEenOpenluchttheater'
import OutdoorFitnessenPage from './pages/Generated/PageOutdoorFitnessen'
import PaardrijdenPage from './pages/Generated/PagePaardrijden'
import PaintballenPage from './pages/Generated/PagePaintballen'
import ParachutespringenPage from './pages/Generated/PageParachutespringen'
import ParaglidenPage from './pages/Generated/PageParagliden'
import ParapentenPage from './pages/Generated/PageParapenten'
import PicknickenPage from './pages/Generated/PagePicknicken'
import HetBezoekenVanEenPretparkPage from './pages/Generated/PageHetBezoekenVanEenPretpark'
import RacefietsenPage from './pages/Generated/PageRacefietsen'
import RaftenPage from './pages/Generated/PageRaften'
import HetMakenVanEenRoadtripPage from './pages/Generated/PageHetMakenVanEenRoadtrip'
import EenRoofvogelshowPage from './pages/Generated/PageEenRoofvogelshow'
import RotsklimmenPage from './pages/Generated/PageRotsklimmen'
import SegwayRijdenPage from './pages/Generated/PageSegwayRijden'
import SkateboardenPage from './pages/Generated/PageSkateboarden'
import SkatenPage from './pages/Generated/PageSkaten'
import SkienPage from './pages/Generated/PageSkien'
import SnowboardenPage from './pages/Generated/PageSnowboarden'
import SlackliningPage from './pages/Generated/PageSlacklining'
import SnorkelenPage from './pages/Generated/PageSnorkelen'
import StandUpPaddleboardenPage from './pages/Generated/PageStandUpPaddleboarden'
import SterrenKijkenPage from './pages/Generated/PageSterrenKijken'
import StrandwandelenPage from './pages/Generated/PageStrandwandelen'
import StrandzeilenPage from './pages/Generated/PageStrandzeilen'
import EenTuinfeestPage from './pages/Generated/PageEenTuinfeest'
import TuinierenPage from './pages/Generated/PageTuinieren'
import VissenPage from './pages/Generated/PageVissen'
import VliegerenPage from './pages/Generated/PageVliegeren'
import VogelsSpottenPage from './pages/Generated/PageVogelsSpotten'
import WandelenPage from './pages/Generated/PageWandelen'
import WatersportenPage from './pages/Generated/PageWatersporten'
import EenWijnproeverijPage from './pages/Generated/PageEenWijnproeverij'
import WielrennenPage from './pages/Generated/PageWielrennen'
import WildwatervarenPage from './pages/Generated/PageWildwatervaren'
import WindsurfenPage from './pages/Generated/PageWindsurfen'
import WingfoilenPage from './pages/Generated/PageWingfoilen'
import ZeilenPage from './pages/Generated/PageZeilen'
import ZonnebadenPage from './pages/Generated/PageZonnebaden'
import EenDakreparatiePage from './pages/Generated/PageEenDakreparatie'
import BuitenschilderwerkPage from './pages/Generated/PageBuitenschilderwerk'
import GrasmaaienPage from './pages/Generated/PageGrasmaaien'
import BestratingLeggenPage from './pages/Generated/PageBestratingLeggen'
import TegelwerkPage from './pages/Generated/PageTegelwerk'
import BuitendeurenOfRamenVervangenPage from './pages/Generated/PageBuitendeurenOfRamenVervangen'
import TuinmeubilairBeitsenPage from './pages/Generated/PageTuinmeubilairBeitsen'
import HetReinigenVanDeBuitengevelPage from './pages/Generated/PageHetReinigenVanDeBuitengevel'
import HetWiedenVanOnkruidPage from './pages/Generated/PageHetWiedenVanOnkruid'
import RamenZemenPage from './pages/Generated/PageRamenZemen'
import HetAanleggenVanEenVijverPage from './pages/Generated/PageHetAanleggenVanEenVijver'
import HetAanleggenVanEenTerrasPage from './pages/Generated/PageHetAanleggenVanEenTerras'
import HetBouwenVanEenOverkappingPage from './pages/Generated/PageHetBouwenVanEenOverkapping'
import HetEgaliserenVanGrondPage from './pages/Generated/PageHetEgaliserenVanGrond'
import HetVerwijderenVanMosEnAlgenPage from './pages/Generated/PageHetVerwijderenVanMosEnAlgen'
import HetPlaatsenVanEenSchuttingPage from './pages/Generated/PageHetPlaatsenVanEenSchutting'
import HetSchoonmakenVanDakgotenPage from './pages/Generated/PageHetSchoonmakenVanDakgoten'
import HetReparerenVanEenBuitenkraanPage from './pages/Generated/PageHetReparerenVanEenBuitenkraan'
import HetInstallerenVanBuitenverlichtingPage from './pages/Generated/PageHetInstallerenVanBuitenverlichting'
import GrondverzetPage from './pages/Generated/PageGrondverzet'
import HetHerstellenVanBestratingPage from './pages/Generated/PageHetHerstellenVanBestrating'
import HetPlaatsenVanEenHekwerkPage from './pages/Generated/PageHetPlaatsenVanEenHekwerk'
import ZwembadonderhoudPage from './pages/Generated/PageZwembadonderhoud'
import HetSchoonmakenVanBuitenmeubilairPage from './pages/Generated/PageHetSchoonmakenVanBuitenmeubilair'
import HetReinigenVanDeBarbecuePage from './pages/Generated/PageHetReinigenVanDeBarbecue'
import HetWassenVanDeAutoPage from './pages/Generated/PageHetWassenVanDeAuto'
import HetWaxenVanDeAutoPage from './pages/Generated/PageHetWaxenVanDeAuto'
import SnoeienPage from './pages/Generated/PageSnoeien'
import RoeienPage from './pages/Generated/PageRoeien'
import HetBezoekenVanEenKinderboerderijPage from './pages/Generated/PageHetBezoekenVanEenKinderboerderij'
import HetBezoekenVanDeEftelingPage from './pages/Generated/PageHetBezoekenVanDeEfteling'
import HetMakenVanEenLunchwandelingPage from './pages/Generated/PageHetMakenVanEenLunchwandeling'
import HetRijdenInEenCabrioPage from './pages/Generated/PageHetRijdenInEenCabrio'
import HetUitlatenVanDeHondPage from './pages/Generated/PageHetUitlatenVanDeHond'
import HetPakkenVanEenTerrasPage from './pages/Generated/PageHetPakkenVanEenTerras'
import HetSproeienVanDeTuinPage from './pages/Generated/PageHetSproeienVanDeTuin'
import HetBushcraftenPage from './pages/Generated/PageHetBushcraften'
import HetAfstekenVanVuurwerkPage from './pages/Generated/PageHetAfstekenVanVuurwerk'
import VoetballenPage from './pages/Generated/PageVoetballen'
import HonkballenPage from './pages/Generated/PageHonkballen'
import HockeyenPage from './pages/Generated/PageHockeyen'
import HetFotograferenVanLandschappenPage from './pages/Generated/PageHetFotograferenVanLandschappen'

ReactGA.initialize('G-MVX5T24GQ4', {
  testMode: process.env.NODE_ENV != 'production'
});

function App() {

  // Not required when you enable "Enhanced measurement" for the Google Analytics "Web stream"
  // However, not working with React-Helmet see: https://github.com/nfl/react-helmet/issues/189#issuecomment-698953347
  useEffect(() => {
    setTimeout(() => {
      ReactGA.send( { hitType: 'pageview', page: window.location.pathname/* + window.location.search*/ });
    }, 500);
  }, []);

  // useEffect(() => {
  //   WebFont.load({
  //     google: {
  //       families: ['Material Icons']
  //     }
  //   });
  //  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <NavBar />
        <Routes>
          {/* Keep in sync with sitemap.xml */}
          <Route path='/' element={<HomePage/>} />
          <Route path='/whatsnew' element={<WhatsNewPage/>} />
          <Route path='/maak-je-eigen-weermelding' element={<CreateAlarmPage/>} />
          <Route path='/alerts/weerwaarschuwingen-knmi' element={<WeatherAlertPage/>} />
          <Route path='/alerts/buitenkraan-afsluiten-bij-vorst' element={<OutsideTapPage/>} />
          <Route path='/alerts/planten-beschermen-tegen-vorst' element={<ProtectPlantsPage/>} />
          <Route path='/alerts/generated-page' element={<GeneratedPage/>} />
          <Route path='/details/:queryBase64' element={<DetailsPage/>} />
          {/* <Route path="*" element={<NotFound/>}/> */}
          <Route path='/alerts/een-ballonvaart' element={<EenBallonvaartPage />} />
          <Route path='/alerts/barbecueen' element={<BarbecueenPage />} />
          <Route path='/alerts/beachvolleybal' element={<BeachvolleybalPage />} />
          <Route path='/alerts/bergbeklimmen' element={<BergbeklimmenPage />} />
          <Route path='/alerts/boogschieten' element={<BoogschietenPage />} />
          <Route path='/alerts/een-boottocht' element={<EenBoottochtPage />} />
          <Route path='/alerts/boulderen' element={<BoulderenPage />} />
          <Route path='/alerts/buiten-klimmen' element={<BuitenKlimmenPage />} />
          <Route path='/alerts/buiten-yoga' element={<BuitenYogaPage />} />
          <Route path='/alerts/buiten-zwemmen' element={<BuitenZwemmenPage />} />
          <Route path='/alerts/buitenconcerten' element={<BuitenconcertenPage />} />
          <Route path='/alerts/buitentheater' element={<BuitentheaterPage />} />
          <Route path='/alerts/canyoning' element={<CanyoningPage />} />
          <Route path='/alerts/deltavliegen' element={<DeltavliegenPage />} />
          <Route path='/alerts/diepzeeduiken' element={<DiepzeeduikenPage />} />
          <Route path='/alerts/het-bezoeken-van-een-dierentuin' element={<HetBezoekenVanEenDierentuinPage />} />
          <Route path='/alerts/duiken' element={<DuikenPage />} />
          <Route path='/alerts/een-festival' element={<EenFestivalPage />} />
          <Route path='/alerts/fietsen' element={<FietsenPage />} />
          <Route path='/alerts/frisbeeen' element={<FrisbeeenPage />} />
          <Route path='/alerts/golfen' element={<GolfenPage />} />
          <Route path='/alerts/gravelbiken' element={<GravelbikenPage />} />
          <Route path='/alerts/een-helikoptervlucht' element={<EenHelikoptervluchtPage />} />
          <Route path='/alerts/ijsklimmen' element={<IJsklimmenPage />} />
          <Route path='/alerts/kajakken' element={<KajakkenPage />} />
          <Route path='/alerts/kamperen' element={<KamperenPage />} />
          <Route path='/alerts/kanoen' element={<KanoenPage />} />
          <Route path='/alerts/kitesurfen' element={<KitesurfenPage />} />
          <Route path='/alerts/mountainbiken' element={<MountainbikenPage />} />
          <Route path='/alerts/musea-bezoeken' element={<MuseaBezoekenPage />} />
          <Route path='/alerts/off-road-rijden' element={<OffRoadRijdenPage />} />
          <Route path='/alerts/het-bezoeken-van-een-openluchttheater' element={<HetBezoekenVanEenOpenluchttheaterPage />} />
          <Route path='/alerts/outdoor-fitnessen' element={<OutdoorFitnessenPage />} />
          <Route path='/alerts/paardrijden' element={<PaardrijdenPage />} />
          <Route path='/alerts/paintballen' element={<PaintballenPage />} />
          <Route path='/alerts/parachutespringen' element={<ParachutespringenPage />} />
          <Route path='/alerts/paragliden' element={<ParaglidenPage />} />
          <Route path='/alerts/parapenten' element={<ParapentenPage />} />
          <Route path='/alerts/picknicken' element={<PicknickenPage />} />
          <Route path='/alerts/het-bezoeken-van-een-pretpark' element={<HetBezoekenVanEenPretparkPage />} />
          <Route path='/alerts/racefietsen' element={<RacefietsenPage />} />
          <Route path='/alerts/raften' element={<RaftenPage />} />
          <Route path='/alerts/het-maken-van-een-roadtrip' element={<HetMakenVanEenRoadtripPage />} />
          <Route path='/alerts/een-roofvogelshow' element={<EenRoofvogelshowPage />} />
          <Route path='/alerts/rotsklimmen' element={<RotsklimmenPage />} />
          <Route path='/alerts/segway-rijden' element={<SegwayRijdenPage />} />
          <Route path='/alerts/skateboarden' element={<SkateboardenPage />} />
          <Route path='/alerts/skaten' element={<SkatenPage />} />
          <Route path='/alerts/skien' element={<SkienPage />} />
          <Route path='/alerts/snowboarden' element={<SnowboardenPage />} />
          <Route path='/alerts/slacklining' element={<SlackliningPage />} />
          <Route path='/alerts/snorkelen' element={<SnorkelenPage />} />
          <Route path='/alerts/stand-up-paddleboarden' element={<StandUpPaddleboardenPage />} />
          <Route path='/alerts/sterren-kijken' element={<SterrenKijkenPage />} />
          <Route path='/alerts/strandwandelen' element={<StrandwandelenPage />} />
          <Route path='/alerts/strandzeilen' element={<StrandzeilenPage />} />
          <Route path='/alerts/een-tuinfeest' element={<EenTuinfeestPage />} />
          <Route path='/alerts/tuinieren' element={<TuinierenPage />} />
          <Route path='/alerts/vissen' element={<VissenPage />} />
          <Route path='/alerts/vliegeren' element={<VliegerenPage />} />
          <Route path='/alerts/vogels-spotten' element={<VogelsSpottenPage />} />
          <Route path='/alerts/wandelen' element={<WandelenPage />} />
          <Route path='/alerts/watersporten' element={<WatersportenPage />} />
          <Route path='/alerts/een-wijnproeverij' element={<EenWijnproeverijPage />} />
          <Route path='/alerts/wielrennen' element={<WielrennenPage />} />
          <Route path='/alerts/wildwatervaren' element={<WildwatervarenPage />} />
          <Route path='/alerts/windsurfen' element={<WindsurfenPage />} />
          <Route path='/alerts/wingfoilen' element={<WingfoilenPage />} />
          <Route path='/alerts/zeilen' element={<ZeilenPage />} />
          <Route path='/alerts/zonnebaden' element={<ZonnebadenPage />} />
          <Route path='/alerts/een-dakreparatie' element={<EenDakreparatiePage />} />
          <Route path='/alerts/buitenschilderwerk' element={<BuitenschilderwerkPage />} />
          <Route path='/alerts/grasmaaien' element={<GrasmaaienPage />} />
          <Route path='/alerts/bestrating-leggen' element={<BestratingLeggenPage />} />
          <Route path='/alerts/tegelwerk' element={<TegelwerkPage />} />
          <Route path='/alerts/buitendeuren-of-ramen-vervangen' element={<BuitendeurenOfRamenVervangenPage />} />
          <Route path='/alerts/tuinmeubilair-beitsen' element={<TuinmeubilairBeitsenPage />} />
          <Route path='/alerts/het-reinigen-van-de-buitengevel' element={<HetReinigenVanDeBuitengevelPage />} />
          <Route path='/alerts/het-wieden-van-onkruid' element={<HetWiedenVanOnkruidPage />} />
          <Route path='/alerts/ramen-zemen' element={<RamenZemenPage />} />
          <Route path='/alerts/het-aanleggen-van-een-vijver' element={<HetAanleggenVanEenVijverPage />} />
          <Route path='/alerts/het-aanleggen-van-een-terras' element={<HetAanleggenVanEenTerrasPage />} />
          <Route path='/alerts/het-bouwen-van-een-overkapping' element={<HetBouwenVanEenOverkappingPage />} />
          <Route path='/alerts/het-egaliseren-van-grond' element={<HetEgaliserenVanGrondPage />} />
          <Route path='/alerts/het-verwijderen-van-mos-en-algen' element={<HetVerwijderenVanMosEnAlgenPage />} />
          <Route path='/alerts/het-plaatsen-van-een-schutting' element={<HetPlaatsenVanEenSchuttingPage />} />
          <Route path='/alerts/het-schoonmaken-van-dakgoten' element={<HetSchoonmakenVanDakgotenPage />} />
          <Route path='/alerts/het-repareren-van-een-buitenkraan' element={<HetReparerenVanEenBuitenkraanPage />} />
          <Route path='/alerts/het-installeren-van-buitenverlichting' element={<HetInstallerenVanBuitenverlichtingPage />} />
          <Route path='/alerts/grondverzet' element={<GrondverzetPage />} />
          <Route path='/alerts/het-herstellen-van-bestrating' element={<HetHerstellenVanBestratingPage />} />
          <Route path='/alerts/het-plaatsen-van-een-hekwerk' element={<HetPlaatsenVanEenHekwerkPage />} />
          <Route path='/alerts/zwembadonderhoud' element={<ZwembadonderhoudPage />} />
          <Route path='/alerts/het-schoonmaken-van-buitenmeubilair' element={<HetSchoonmakenVanBuitenmeubilairPage />} />
          <Route path='/alerts/het-reinigen-van-de-barbecue' element={<HetReinigenVanDeBarbecuePage />} />
          <Route path='/alerts/het-wassen-van-de-auto' element={<HetWassenVanDeAutoPage />} />
          <Route path='/alerts/het-waxen-van-de-auto' element={<HetWaxenVanDeAutoPage />} />
          <Route path='/alerts/snoeien' element={<SnoeienPage />} />
          <Route path='/alerts/roeien' element={<RoeienPage />} />
          <Route path='/alerts/het-bezoeken-van-een-kinderboerderij' element={<HetBezoekenVanEenKinderboerderijPage />} />
          <Route path='/alerts/het-bezoeken-van-de-efteling' element={<HetBezoekenVanDeEftelingPage />} />
          <Route path='/alerts/het-maken-van-een-lunchwandeling' element={<HetMakenVanEenLunchwandelingPage />} />
          <Route path='/alerts/het-rijden-in-een-cabrio' element={<HetRijdenInEenCabrioPage />} />
          <Route path='/alerts/het-uitlaten-van-de-hond' element={<HetUitlatenVanDeHondPage />} />
          <Route path='/alerts/het-pakken-van-een-terras' element={<HetPakkenVanEenTerrasPage />} />
          <Route path='/alerts/het-sproeien-van-de-tuin' element={<HetSproeienVanDeTuinPage />} />
          <Route path='/alerts/het-bushcraften' element={<HetBushcraftenPage />} />
          <Route path='/alerts/het-afsteken-van-vuurwerk' element={<HetAfstekenVanVuurwerkPage />} />
          <Route path='/alerts/voetballen' element={<VoetballenPage />} />
          <Route path='/alerts/honkballen' element={<HonkballenPage />} />
          <Route path='/alerts/hockeyen' element={<HockeyenPage />} />
          <Route path='/alerts/het-fotograferen-van-landschappen' element={<HetFotograferenVanLandschappenPage />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
