import { Col, Container, Row, Stack } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import downloadApple from '../../media/app-store-badge-nl.png';
import downloadGoogle from '../../media/google-play-badge-nl.png';

const HetBezoekenVanEenOpenluchttheaterPage = () => {

  const navigate = useNavigate();

  return (
    <div className="App">

      <Helmet prioritizeSeoTags>
        <title>Het bezoeken van een openluchttheater | BuzzerBee weermeldingen</title>
        <meta name="description" content="Wat is het beste weer voor het bezoeken van een openluchttheater? Ontvang op tijd een melding voor jouw locatie." />
        <link rel="canonical" href="https://buzzerbee.buzzerbee.nl/alerts/het-bezoeken-van-een-openluchttheater" />
      </Helmet>

      <div className="Header">
        <Container>
          <Row>
            <Col>
              <h1 className="mt-2 mb-4">Het bezoeken van een openluchttheater</h1>
              <p>
                Wil je gewaarschuwd worden als het goed weer wordt om een openluchttheater te bezoeken?
              </p>
              <p>
                Installeer dan de <Link to={'/'}>BuzzerBee app</Link> op jouw iPhone of Android-smartphone, klik daarna op onderstaande knop en pas de melding helemaal naar je eigen wensen aan. Je ontvangt dan een gratis notificatie als het goed weer is voor het bezoeken van een openluchttheater op de locatie die jij hebt ingesteld.
              </p>

              <Stack direction="horizontal" className="AlarmContainer mb-4" onClick={() => {
                window.location.href = `/details/djI7SGV0IGJlem9la2VuIHZhbiBlZW4gb3Blbmx1Y2h0dGhlYXRlcjslRjAlOUYlOEUlQUQ7MDs7MDswOzE7OzA7MTI7MTU7MjswOTowMDswOy0xMDs0MDswOy0xMDs0MDswOzE7MDswOzIwOzA7MDsxMjswOzA7MDswOzE7MjA7NDA=`;
                return null;
              }}>
                <div className="AlarmColumn" style={{fontSize: 35, marginLeft: -15, marginRight: -10}}>🎭</div>
                <div className="AlarmColumn" style={{textAlign: 'left'}}>Het bezoeken van een openluchttheater</div>
              </Stack>

              <h2>Wat is het ideale weer om een openluchttheater te bezoeken?</h2>
              <p>
                Het beste weer voor het bezoeken van een openluchttheater is <b>droog weer en niet te warm of te koud</b>. Wil je weten wanneer er op jouw locatie goede weersomstandigheden zijn om een openluchttheater te bezoeken? Installeer dan de gratis BuzzerBee app op jouw mobiel:
              </p>
              <Container className='download-button-container justify-content-center'>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="download-button-item download-button-ios">
                      <a href="https://apps.apple.com/nl/app/buzzerbee/id1539478065" target={'_blank'}>
                        <img src={downloadApple} className="download-button" alt="logo" />  
                      </a>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='download-button-item download-button-android'>
                      <a href="https://play.google.com/store/apps/details?id=nl.buzzerbee.app&gl=NL" target={'_blank'}>
                        <img src={downloadGoogle} className="download-button" alt="logo" />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>

    </div>
  );

}
export default HetBezoekenVanEenOpenluchttheaterPage;
