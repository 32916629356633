import React, { useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import infinityVideo from '../media/infinity.mp4';
// import sortAlarmsVideo from '../media/sort-alarms.mp4';
import sortAlarmsImage from '../media/sort-alarms.png';
import infinityImage from '../media/infinity.png';
import mutedAlarms from '../media/muted-alarms.png';
import badgeCountImage from '../media/badge-count.png';
import shareAlarmImage from '../media/share-alarm.png';
import protectPlantsImage from '../media/protect-plants.png';
import editAlarmConditionsImage from '../media/edit-alarm-conditions.png';
import editAlarmTermsImage from '../media/edit-alarm-terms.png';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const CreateAlarmPage = () => {

  return (
    <div className="App">

      <Helmet prioritizeSeoTags>
        <title>Maak je eigen weermelding | BuzzerBee weermeldingen</title>
        <link rel="canonical" href="https://buzzerbee.buzzerbee.nl/maak-je-eigen-weermelding" />
      </Helmet>
      
      <div className="Header mb-4">
        <Container>
          <Row>
            <Col lg={8}>
              <h1 className="mt-2 mb-4">
                Maak je eigen weermelding
              </h1>
              {/* <p className="font-italic" style={{fontStyle: 'italic', marginTop: -20}}>
                Versie 0.3.4
              </p> */}
              <p>
                Met de <Link to={'/'}>BuzzerBee app</Link> kun je je eigen weermeldingen samenstellen.
                Dit doe je door de gewenste weerconditities te selecteren.
              </p>
              <p>
                Je hebt hierbij de keuze uit de volgende weercondities:
                <ul>
                  <li>locatie</li>
                  <li>dagtemperatuur</li>
                  <li>nachttemperatuur</li>
                  <li>bewolking/zon</li>
                  <li>neerslag</li>
                  <li>windkracht</li>
                  <li>windrichting</li>
                </ul>
              </p>
            </Col>
            <Col lg={4} style={{ display: 'flex', alignItems:'center', justifyContent: 'center'}}>
              <img
                className="img-screenshot"
                alt="edit-alarm-conditions-image"
                src={editAlarmConditionsImage}
                style={{ maxWidth: '90%', maxHeight: '60vh' }}
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg={8}>
              <p>
                Onder het kopje Voorwaarden kun je instellen wanneer en voor welke dagen/tijden je een notificatie wil ontvangen.
                <ul>
                  <li><b>notificatietermijn</b>: hoeveel dagen van tevoren je de notificatie wil ontvangen, en hoe laat</li>
                  <li><b>weekdagen</b>: voor welke dagen van de week je de weercondities wil checken</li>
                  <li><b>tijdvak</b>: voor welk tijdframe je de weercondities wil checken</li>
                  <li><b>dempen na melding</b>: als de melding na een notificatie automatisch uitgeschakeld moet worden. Je kunt hier ook instellen wanneer de melding weer opnieuw actief moet worden.</li>
                </ul>
              </p>
            </Col>
            <Col lg={4} style={{ display: 'flex', alignItems:'center', justifyContent: 'center'}}>
              <img
                className="img-screenshot"
                alt="edit-alarm-terms-image"
                src={editAlarmTermsImage}
                style={{ maxWidth: '90%', maxHeight: '43vh' }}
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <p>
                Heb je vragen of tips over het instellen van weermeldingen? Laat het ons weten via de app.
              </p>
            </Col>
          </Row>

        </Container>
      </div>

    </div>
  );

}
export default CreateAlarmPage;
