import React, { useRef, useEffect, useState } from 'react';
import downloadApple from '../media/app-store-badge-nl.png';
import downloadGoogle from '../media/google-play-badge-nl.png';
import '../App.css';
import { Container, Row, Col, Stack } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';
import Query from '../models/Query';

const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const DetailsPage = (props: any) => {

  // The '*' in the path match gets assigned to this.props.params.splat
  //const path = props.params.splat;
  let params = useParams();
  // console.log(JSON.stringify(params));
  const queryBase64 = params.queryBase64;
  let query: Query | null = null;
  if (queryBase64) {
    try {
      query = Query.parse(queryBase64);
      // console.log(JSON.stringify(query));
    } catch (error) {      
    }
  }

  return (
    <div className="App">

      <Helmet prioritizeSeoTags>
        <title>Melding: {query?.name ?? 'Onbekend'} | BuzzerBee weermeldingen</title>
        {/* <link rel="canonical" href={`https://buzzerbee.buzzerbee.nl/details/${queryBase64}`} /> */}
      </Helmet>

      { query ? (
        <div className="Header">
          {/* <h1 className="mt-2 mb-4">
            {query!.emoji} {query!.name}
          </h1> */}
          <p className="header-text">
            Installeer eerst de <Link to={'/'}>Buzzerbee app</Link> en klik op onderstaande link om deze melding toe te voegen aan jouw alarmen.
          </p>
          <Stack direction="horizontal" className="AlarmContainer mb-4" onClick={() => {
            console.log(`buzzerbee:/${window.location.pathname}`);
            window.location.href = `buzzerbee:/${window.location.pathname}`; // returns the current url minus the domain name (starting with '/')
            return null;
          }}>
            <div className="AlarmColumn" style={{fontSize: 35, marginLeft: -15, marginRight: -10}}>{query?.emoji ?? ''}</div>
            <div className="AlarmColumn" style={{textAlign: 'left'}}>{query?.name ?? 'Onbekend'}</div>
          </Stack>
        </div>
      ) : (
        <div className="Header">
          <p className="header-text">
            Melding niet gevonden.
          </p>
          <p className="header-text">
            Installeer de <Link to={'/'}>Buzzerbee app</Link> om je eigen weermeldingen aan te maken.
          </p>
        </div>
      ) }

    </div>
  );
}
export default DetailsPage;
