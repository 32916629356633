import React from 'react';
// import {  Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import beeLogo from '../../media/bee-512.png';

const NavBar = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="navbar">
      <Container>
        <Navbar.Brand href="/">
          <img
            alt=""
            src={beeLogo}
            width="30"
            height="30"
            className="navbar-logo d-inline-block align-top"
          />
          BuzzerBee
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/whatsnew">What's new</Nav.Link>
            <Nav.Link href="/maak-je-eigen-weermelding">Maak je eigen weermelding</Nav.Link>
            {/* <Nav.Link href="/gettheapp">Download</Nav.Link> */}
            <NavDropdown title="Toepassingen" id="basic-nav-dropdown">
              <NavDropdown.Item href="/alerts/weerwaarschuwingen-knmi">⚠️ Weerwaarschuwingen KNMI</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/alerts/buitenkraan-afsluiten-bij-vorst">🚰 Buitenkraan afsluiten bij vorst</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/planten-beschermen-tegen-vorst">🌱 Planten beschermen tegen vorst</NavDropdown.Item>
              <NavDropdown.Divider />
              {/* START GENERATED */}
              <NavDropdown.Item href="/alerts/een-ballonvaart">🎈 Een ballonvaart</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/barbecueen">♨️ Barbecueën</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/beachvolleybal">🏐 Beachvolleybal</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/bergbeklimmen">🧗 Bergbeklimmen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/boogschieten">🏹 Boogschieten</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/een-boottocht">🛥️ Een boottocht</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/boulderen">🧗 Boulderen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/buiten-klimmen">🧗 Buiten klimmen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/buiten-yoga">🧘 Buiten yoga</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/buiten-zwemmen">🏊 Buiten zwemmen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/buitenconcerten">🎸 Buitenconcerten</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/buitentheater">🎭 Buitentheater</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/canyoning">🧗 Canyoning</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/deltavliegen">🪂 Deltavliegen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/diepzeeduiken">🤿 Diepzeeduiken</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-bezoeken-van-een-dierentuin">🐘 Het bezoeken van een dierentuin</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/duiken">🤿 Duiken</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/een-festival">🎪 Een festival</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/fietsen">🚲 Fietsen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/frisbeeen">🥏 Frisbeeën</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/golfen">🏌️ Golfen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/gravelbiken">🚵 Gravelbiken</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/een-helikoptervlucht">🚁 Een helikoptervlucht</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/ijsklimmen">🧗 IJsklimmen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/kajakken">🛶 Kajakken</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/kamperen">🏕️ Kamperen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/kanoen">🛶 Kanoën</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/kitesurfen">🪁 Kitesurfen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/mountainbiken">🚵 Mountainbiken</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/musea-bezoeken">🖼️ Musea bezoeken</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/off-road-rijden">🚘 Off-road rijden</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-bezoeken-van-een-openluchttheater">🎭 Het bezoeken van een openluchttheater</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/outdoor-fitnessen">🏋️ Outdoor fitnessen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/paardrijden">🏇 Paardrijden</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/paintballen">🎨 Paintballen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/parachutespringen">🪂 Parachutespringen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/paragliden">🪂 Paragliden</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/parapenten">🪂 Parapenten</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/picknicken">🍱 Picknicken</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-bezoeken-van-een-pretpark">🎢 Het bezoeken van een pretpark</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/racefietsen">🚴 Racefietsen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/raften">🛶 Raften</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-maken-van-een-roadtrip">🚗 Het maken van een roadtrip</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/een-roofvogelshow">🦅 Een roofvogelshow</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/rotsklimmen">🧗 Rotsklimmen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/segway-rijden">🛴 Segway rijden</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/skateboarden">🛹 Skateboarden</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/skaten">🛼 Skaten</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/skien">⛷️ Skiën</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/snowboarden">🏂 Snowboarden</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/slacklining">🪢 Slacklining</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/snorkelen">🤿 Snorkelen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/stand-up-paddleboarden">🏄 Stand-up paddleboarden</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/sterren-kijken">🔭 Sterren kijken</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/strandwandelen">🏖️ Strandwandelen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/strandzeilen">⛵ Strandzeilen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/een-tuinfeest">🏡 Een tuinfeest</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/tuinieren">🪴 Tuinieren</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/vissen">🎣 Vissen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/vliegeren">🪁 Vliegeren</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/vogels-spotten">🔭 Vogels spotten</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/wandelen">🥾 Wandelen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/watersporten">🌊 Watersporten</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/een-wijnproeverij">🍷 Een wijnproeverij</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/wielrennen">🚴 Wielrennen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/wildwatervaren">🛶 Wildwatervaren</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/windsurfen">🏄 Windsurfen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/wingfoilen">🏄 Wingfoilen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/zeilen">⛵ Zeilen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/zonnebaden">☀️ Zonnebaden</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/een-dakreparatie">🏠 Een dakreparatie</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/buitenschilderwerk">🎨 Buitenschilderwerk</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/grasmaaien">🌿 Grasmaaien</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/bestrating-leggen">🪨 Bestrating leggen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/tegelwerk">⛏️ Tegelwerk</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/buitendeuren-of-ramen-vervangen">🚪 Buitendeuren of ramen vervangen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/tuinmeubilair-beitsen">🛋️ Tuinmeubilair beitsen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-reinigen-van-de-buitengevel">🏠 Het reinigen van de buitengevel</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-wieden-van-onkruid">🌿 Het wieden van onkruid</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/ramen-zemen">🧽 Ramen zemen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-aanleggen-van-een-vijver">🐟 Het aanleggen van een vijver</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-aanleggen-van-een-terras">⛏️ Het aanleggen van een terras</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-bouwen-van-een-overkapping">⛩️ Het bouwen van een overkapping</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-egaliseren-van-grond">👷 Het egaliseren van grond</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-verwijderen-van-mos-en-algen">🌿 Het verwijderen van mos en algen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-plaatsen-van-een-schutting">⛏️ Het plaatsen van een schutting</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-schoonmaken-van-dakgoten">🏠 Het schoonmaken van dakgoten</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-repareren-van-een-buitenkraan">🚰 Het repareren van een buitenkraan</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-installeren-van-buitenverlichting">💡 Het installeren van buitenverlichting</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/grondverzet">👷 Grondverzet</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-herstellen-van-bestrating">⛏️ Het herstellen van bestrating</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-plaatsen-van-een-hekwerk">👷 Het plaatsen van een hekwerk</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/zwembadonderhoud">🏊 Zwembadonderhoud</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-schoonmaken-van-buitenmeubilair">🛋️ Het schoonmaken van buitenmeubilair</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-reinigen-van-de-barbecue">🧹 Het reinigen van de barbecue</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-wassen-van-de-auto">🧼 Het wassen van de auto</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-waxen-van-de-auto">🧽 Het waxen van de auto</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/snoeien">🌿 Snoeien</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/roeien">🚣 Roeien</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-bezoeken-van-een-kinderboerderij">🐖 Het bezoeken van een kinderboerderij</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-bezoeken-van-de-efteling">🎢 Het bezoeken van de Efteling</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-maken-van-een-lunchwandeling">🚶 Het maken van een lunchwandeling</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-rijden-in-een-cabrio">🚘 Het rijden in een cabrio</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-uitlaten-van-de-hond">🐕 Het uitlaten van de hond</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-pakken-van-een-terras">🍹 Het pakken van een terras</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-sproeien-van-de-tuin">💦 Het sproeien van de tuin</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-bushcraften">⛺ Het bushcraften</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-afsteken-van-vuurwerk">🎇 Het afsteken van vuurwerk</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/voetballen">⚽ Voetballen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/honkballen">⚾ Honkballen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/hockeyen">🏑 Hockeyen</NavDropdown.Item>
              <NavDropdown.Item href="/alerts/het-fotograferen-van-landschappen">📷 Het fotograferen van landschappen</NavDropdown.Item>
              {/* END GENERATED */}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;